import { mapGetters, mapState } from 'vuex'

import { LOCAL_STORAGE_ID } from "@/utils/relatorioCancelados"

export const computed = {
  ...mapState('auth', ['empresaAtual']),
  ...mapState('fornecedores', ['fornecedores']),
  ...mapState('clientes', ['clientes']),
  ...mapState('transportadoras', ['transportadoras']),
  ...mapGetters('contratos', ['terminais']),
  ...mapState('patio', ['regioes', 'representantes']),
  ...mapState('motoristas', ['motoristas']),

  showData() {
    return this.params.data_inicio.visible || this.params.data_fim.visible
  },
  showFornecedor() {
    return this.params.fornecedor.visible || this.params.produto.visible || this.params.sub_produto.visible
  },
  showContratos() {
    return this.params.terminal.visible || this.params.cliente.visible || this.params.transportadora.visible
  },
  showRegiao() {
    return this.params.regiao.visible || this.params.representante.visible
  },
  showVeiculo() {
    return this.params.motorista.visible || this.params.placa_cavalo.visible || this.params.eixo.visible
  },
  areSettingsEmpty() {
    return !(this.showData || this.showFornecedor || this.showContratos || this.showRegiao || this.showVeiculo)
  },
  selectedOptions() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)) || []
  }
}
