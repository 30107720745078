<template>
  <v-container fluid>
    <BaseBreadcrumb :tela="permission" :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col>
        <relatorio-cancelados-list-filtro @search="handleGetAgendamentos" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Relatório de Cancelados">
          <v-dialog v-model="showComprovante" width="700px">
            <v-card>
              <comprovante />
            </v-card>
          </v-dialog>
          <v-dialog v-model="showComprovanteAgendamento" fullscreen>
            <v-card>
              <comprovante-agendamento />
            </v-card>
          </v-dialog>

          <v-dialog v-model="showComprovanteLaudo">
            <v-card>
              <comprovante-laudo />
            </v-card>
          </v-dialog>

          <template slot="right">
            <v-dialog v-model="dialogIndicadores" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn v-if="canList" color="primary" dark class="ml-2 mb-2" v-on="on">Indicadores</v-btn>
              </template>
              <indicadores-gerencimento-servico :headers="headers" @close="closeDialogIndicadores" />
            </v-dialog>
            <v-btn v-if="canList" color="primary" dark class="ml-2 mb-2" @click="handleGetAgendamentos">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>

          <relatorio-cancelados-list :headers="headersComputed" ref="listagem" />

          <div v-if="!canList" align="center">
            <span class="red--text">Você não tem permissão para visualizar as informações desta
              tela</span>
          </div>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import RelatorioCanceladosList from '@/components/patio/RelatorioCancelados/RelatorioCanceladosList.vue'
import RelatorioCanceladosListFiltro from '@/components/patio/RelatorioCancelados/RelatorioCanceladosListFiltro.vue'

import IndicadoresGerencimentoServico from '@/components/patio/GerenciamentoServico/IndicadoresGerencimentoServico.vue'
import Comprovante from '@/components/patio/NovoAgendamento/comprovante/Componente.vue'
import ComprovanteAgendamento from '@/components/patio/NovoAgendamento/comprovanteAgendamento/Componente.vue'
import ComprovanteLaudo from '@/components/patio/NovoAgendamento/comprovanteLaudo/Componente.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import { data } from './_data'

const HEADERS_RELATORIO_CANCELADOS_LOCALSTORAGE = 'headersRelatorioCancelados'

export default {
  name: 'RelatorioCancelados',
  provide() {
    return {
      visualizarComprovante: this.visualizarComprovante,
      visualizarComprovanteAgendamento: this.visualizarComprovanteAgendamento,
      visualizarComprovanteLaudo: this.visualizarComprovanteLaudo,
      fecharComprovanteAgendamento: this.fecharComprovanteAgendamento,
    }
  },
  components: {
    RelatorioCanceladosList,
    RelatorioCanceladosListFiltro,
    IndicadoresGerencimentoServico,
    Comprovante,
    ComprovanteAgendamento,
    ComprovanteLaudo,
  },
  data,
  computed: {
    ...mapState('auth', ['empresaAtual']),

    headersComputed() {
      return this.headers.filter((header) => !!header.habilited)
    },

    permission() {
      return Sequences.Cancelados.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  watch: {
    headersComputed() {
      localStorage.setItem(
        HEADERS_RELATORIO_CANCELADOS_LOCALSTORAGE,
        JSON.stringify(this.headers)
      )
    },
  },
  methods: {
    visualizarComprovante() {
      this.showComprovanteAgendamento = false
      this.showComprovanteLaudo = false
      this.showComprovante = true
    },

    visualizarComprovanteAgendamento() {
      this.showComprovante = false
      this.showComprovanteLaudo = false
      this.showComprovanteAgendamento = true
    },

    visualizarComprovanteLaudo() {
      this.showComprovante = false
      this.showComprovanteAgendamento = false
      this.showComprovanteLaudo = true
    },

    fecharComprovanteAgendamento() {
      this.showComprovanteAgendamento = false
    },

    closeDialogIndicadores() {
      this.dialogIndicadores = false
    },

    async handleGetAgendamentos() {
      const { listagem } = this.$refs
      await listagem.handleGetAgendamentos()
    },

    setIndicadores() {
      const headersSaved = localStorage.getItem(
        HEADERS_RELATORIO_CANCELADOS_LOCALSTORAGE
      )

      const headersSavedParsed = JSON.parse(headersSaved)

      if (
        headersSaved &&
        Array.isArray(headersSavedParsed) &&
        headersSavedParsed.length > 0
      ) {
        const headers_saved_map_habilited = headersSavedParsed.filter(item => item.habilited).map(item => item.value)
        const headers_saved_map_not_habilited = headersSavedParsed.filter(item => !item.habilited).map(item => item.value)
        this.headers = this.headers.map(item => {
          let habilited = false
          if (headers_saved_map_habilited.includes(item.value)) {
            habilited = true
          } else {
            if (headers_saved_map_not_habilited.includes(item.value)) {
              habilited = false
            } else {
              // Para os novos
              habilited = item.habilited
            }
          }
          return {
            ...item,
            habilited,
          }
        })
        localStorage.setItem(
          HEADERS_RELATORIO_CANCELADOS_LOCALSTORAGE,
          JSON.stringify(this.headers)
        )
      } else {
        localStorage.setItem(
          HEADERS_RELATORIO_CANCELADOS_LOCALSTORAGE,
          JSON.stringify(this.headers)
        )
      }
    },
  },
  created() {
    if (this.canList) {
      this.setIndicadores()
    }
  },
}
</script>
